import { EHttpMethod } from "@/types/emuns";
import { apiSlice } from "../services/apiSlice";
import { IModel } from "@/types";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    
    retrieveUser: builder.query<IModel.IUserAuth, void>({
      query: () => "/users/me/",
    }),
    retUser: builder.mutation<IModel.IUserAuth, void>({
      query: () => ({
        url: "/users/me/",
        method: EHttpMethod.GET,
        credentials: "include",
      }),
    }),
    userAccount: builder.query<IModel.IUserAuth, void>({
      query: () => ({
        url: "/user/account/",
        method: EHttpMethod.GET,
        credentials: "include",
      }),
    }),
    setAdminAccount: builder.mutation({
      query: (user_name: string) => ({
        url: "/user/admin/",
        method: EHttpMethod.POST,
        credentials: "include",
        body: { user_name: user_name },
      }),
    }),
    socialAuthenticate: builder.mutation<IModel.ICreateUserResponse, IModel.ISocialAuth>({
      query: (args: IModel.ISocialAuth) => ({
        url: `/o/${args.provider}/?state=${encodeURIComponent(args.state)}&code=${encodeURIComponent(args.code)}`,
        method: EHttpMethod.POST,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        credentials: "include",
      }),
    }),
    login: builder.mutation({
      query: (args: IModel.IUser) => ({
        url: "/jwt/create/",
        method: EHttpMethod.POST,
        body: args,
        credentials: "include",
      }),
    }),
    register: builder.mutation({
      query: (args: IModel.IUserRegister) => ({
        url: "/users/",
        method: EHttpMethod.POST,
        body: args,
        credentials: "include",
      }),
    }),
    verify: builder.mutation({
      query: () => ({
        url: "/jwt/verify/",
        method: EHttpMethod.POST,
        credentials: "include",
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/logout/',
        method: 'POST',
      }),
    }),
    activation: builder.mutation({
      query: (args: IModel.IConfirm) => ({
        url: "/users/activation/",
        method: EHttpMethod.POST,
        body: args,
        credentials: "include",
      }),
    }),
    resetPassword: builder.mutation({
      query: (email: string) => ({
        url: "/users/reset_password/",
        method: EHttpMethod.POST,
        body: { email },
        credentials: "include",
      }),
    }),
    resetPasswordConfirm: builder.mutation({
      query: (args: IModel.IResetPassword) => ({
        url: "/users/reset_password_confirm/",
        method: EHttpMethod.POST,
        body: args,
        credentials: "include",
      }),
    }),
    passwordReset: builder.mutation({
      query: () => ({
        url: "/password_reset_mailjet/",
        method: EHttpMethod.POST,
        credentials: "include",
      }),
    }),
    createAccount: builder.mutation<IModel.IUserAuth, FormData>({
      query: (formData) => ({
        url: "/create_account/",
        method: EHttpMethod.POST,
        body: formData,
        credentials: "include",
      }),
    }),
    getUserAccounts: builder.query<IModel.IUserAuth[], { account_status?: string }>({
      query: ({ account_status }) => ({
        url: `/user/accounts/?account_status=${account_status}`,
        method: EHttpMethod.GET,
        credentials: "include",
      }),
    }),
    getIdentificationTypes: builder.query<IModel.IIdentificationType[], void>({
      query: () => ({
        url: "/identification-types/",
        method: EHttpMethod.GET,
        credentials: "include",
      }),
    }),
    changeAccountStatus: builder.mutation<void, { userId: number; statusId: number; comments?: string }>({
      query: ({ userId, statusId, comments }) => ({
        url: `/admin/accounts/${userId}/`,
        method: 'POST',
        credentials: "include",
        body: { 
          status_id: statusId, 
          comments: comments 
        },
      }),
    }),
    getUserHistory: builder.query<IModel.IUserAccountStatus[], number>({
      query: (userId) => ({
        url: `/user/history/${userId}/?status_id=4`,
        method: EHttpMethod.GET,
        credentials: "include",
      }),
    }),
    setAcceptedPopupShown: builder.mutation<void, void>({
      query: () => ({
        url: "/user/set_accepted_popup_shown/",
        method: "POST",
      }),
    }),
    setPopupShown: builder.mutation<void, void>({
      query: () => ({
          url: "/user/set_popup_shown/",
          method: "POST",
          credentials: "include",
      }),
    }),
    checkRejectionHistory: builder.query<{ rejected: boolean }, number>({
      query: (userId) => `user/${userId}/rejection-history/`,
    }),
    updateProfile: builder.mutation<IModel.IUserAuth, FormData>({
      query: (formData) => ({
        url: "/user/update_profile/",
        method: EHttpMethod.PUT, 
        body: formData, 
        credentials: "include",
      }),
    }),
    removeProfilePicture: builder.mutation<void, void>({
      query: () => ({
        url: "/user/remove_profile_picture/",
        method: EHttpMethod.DELETE,
        credentials: "include",
      }),
    }),
    
    
  }),
});

export const {
  useRetrieveUserQuery,
  useRetUserMutation,
  useSocialAuthenticateMutation,
  useLoginMutation,
  useRegisterMutation,
  useVerifyMutation,
  useLogoutMutation,
  useActivationMutation,
  useResetPasswordMutation,
  useResetPasswordConfirmMutation,
  usePasswordResetMutation,
  useCreateAccountMutation,
  useUserAccountQuery,
  useSetAdminAccountMutation,
  useGetUserAccountsQuery,
  useGetIdentificationTypesQuery,
  useChangeAccountStatusMutation,
  useGetUserHistoryQuery,
  useSetPopupShownMutation,
  useSetAcceptedPopupShownMutation,
  useCheckRejectionHistoryQuery,
  useUpdateProfileMutation,
  useRemoveProfilePictureMutation,
} = authApiSlice;



