"use client";

import { useUserAccountQuery } from "@/redux/features/authApiSlice";
import { useAppSelector } from "@/redux/hooks";
import { skipToken } from "@reduxjs/toolkit/query";
import InfogramLayout from '../layouts/InfogramLayout';
import InfogramLayoutLogged from '../layouts/InfogramLayoutLogged';

export default function Infogram() {
  const isAuthenticated = useAppSelector((state) => state.authState.isAuthenticated);
  const { data: userAccount, isLoading, error } = useUserAccountQuery(
    isAuthenticated ? undefined : skipToken
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading infogram</div>;

  return userAccount?.account_status === "Active" ? (
    <InfogramLayoutLogged />
  ) : (
    <InfogramLayout />
  );
}
